import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import CartContext from "../context/cart-context";
import useCoverProduct from "../hooks/useCoverProduct";
import useLinerProduct from "../hooks/useLinerProduct";
import { getQueryStringFromProductVariationAttributes } from "../utilities/get-query-string-from-product-variation-attributes";
import { Price } from "../utilities/price";
import classNames from "classnames";

const componentStyles = {
  bestValueBadge: {
    Wasatch: `bg-${process.env.THEME_COLOR_PRIMARY}-dark text-white`,
    Mountainland: `bg-yellow text-black`,
  },
  productTitle: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}-darker`,
    Mountainland: `text-black`,
  },
  viewProductButton: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}`,
    Mountainland: `text-${process.env.THEME_COLOR_PRIMARY}-dark`,
  },
};

const AllProducts = () => {
  const cartContext = useContext(CartContext);
  const accessoryProducts = cartContext
    .accessoryProducts()
    .map((product) => {
      return product.node;
    })
    .filter((product) => {
      return !product.slug.includes("topper");
    });
  const coverData = useCoverProduct();
  const windowWellCover = coverData ? coverData.node.product.variations : [];
  const skus = [
    "window-well-cover-steel-black",
    "window-well-cover-polycarbonate",
    "window-well-cover-steel-black-poly-cover",
  ];

  const sortMap = {
    "window-well-cover-steel-black": 1,
    "window-well-cover-steel-black-poly-cover": 2,
    "window-well-cover-polycarbonate": 3,
  };
  const windowWellVariations = windowWellCover
    .filter((variation) => {
      return skus.includes(variation.variationSku);
    })
    .sort((a, b) => {
      return sortMap[a.variationSku] - sortMap[b.variationSku];
    });

  const linerData = useLinerProduct();
  const linerProducts = linerData ? linerData.node.product.variations : [];

  return (
    <>
      <div className="container mx-auto my-8 lg:my-12">
        <div className="flex gap-4 lg:gap-12 p-4">
          <div className="hidden md:block w-full md:w-1/3">
            <h3 className="text-lg md:text-xl font-bold mb-8">All Products</h3>
            <h4 className="font-medium mb-2">Window Well Covers</h4>
            {windowWellVariations &&
              windowWellVariations.map((variation, index) => {
                const productAttributesQueryString =
                  getQueryStringFromProductVariationAttributes(
                    variation.variationAttributes
                  );
                return (
                  <Link
                    to={`/window-well-covers/${productAttributesQueryString}`}
                    className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}`}
                    key={index}
                  >
                    {variation.variationName}
                  </Link>
                );
              })}
            <Link
              to="/compare-covers/"
              className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}`}
            >
              View all Covers
            </Link>
            <h4 className="font-medium mb-2 mt-8">Window Well Liners</h4>
            {linerProducts &&
              linerProducts.map((product, index) => {
                return (
                  <Link
                    to={`/window-well-liners/?style=${product.variationName.replace(
                      "Window Well Liner - ",
                      ""
                    )}`}
                    className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}`}
                    id={index}
                    key={index}
                  >
                    {product.variationName.replace("Window Well Liner - ", "")}{" "}
                    Liner
                  </Link>
                );
              })}
            <Link
              to="/window-well-liners/"
              className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}`}
            >
              View all Liners
            </Link>
            <h4 className="font-medium mb-2 mt-8">Accessories</h4>
            {accessoryProducts &&
              accessoryProducts.map((node, index) => {
                return (
                  <Link
                    to={`/${node.slug}/`}
                    className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}`}
                    key={index}
                  >
                    {node.title
                      .replace(`in ${process.env.STATE_NAME}`, "")
                      .replace(`${process.env.COMPANY_NAME_SHORT} `, "")}
                  </Link>
                );
              })}
          </div>
          {/* COLUMN */}
          <div className="w-full md:w-2/3">
            <h3 className="text-lg md:text-xl font-bold mb-8">
              Window Well Covers
            </h3>
            {windowWellVariations &&
              windowWellVariations.map((variation, index) => {
                const productAttributesQueryString =
                  getQueryStringFromProductVariationAttributes(
                    variation.variationAttributes
                  );

                return (
                  <div
                    className="flex flex-wrap md:flex-nowrap mb-6 lg:mb-10 gap-6"
                    key={index}
                  >
                    <div className="w-full md:w-2/5">
                      <Link
                        to={`/window-well-covers/${productAttributesQueryString}`}
                      >
                        <div className="relative">
                          <ProductImage
                            className="rounded-lg h-48 w-full object-cover"
                            image={variation.variationFeaturedImage}
                          />
                          {variation.variationSku ===
                            "window-well-cover-steel-black-poly-cover" && (
                            <div
                              className={classNames(
                                `absolute px-6 py-2 rounded-full bottom-0 left-0 ml-4 mb-4`,
                                componentStyles.bestValueBadge[
                                  process.env.COMPANY_NAME_SHORTER
                                ]
                              )}
                            >
                              Best Value!
                            </div>
                          )}
                        </div>
                      </Link>
                    </div>
                    <div className="w-full flex-1">
                      <h4 className="text-lg font-bold mb-1">
                        <Link
                          to={`/window-well-covers/${productAttributesQueryString}`}
                          className={classNames(
                            `hover:underline`,
                            componentStyles.productTitle[
                              process.env.COMPANY_NAME_SHORTER
                            ]
                          )}
                        >
                          {variation.variationName}
                        </Link>
                      </h4>
                      <div className="text-grey-dark font-thin mb-3">
                        <Price value={variation.variationSalePrice} />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: variation.variationShortDescription,
                        }}
                        className="leading-loose mb-2"
                      />
                      <Link
                        to={`/window-well-covers/${productAttributesQueryString}`}
                        className={classNames(
                          "hover:underline",
                          componentStyles.viewProductButton[
                            process.env.COMPANY_NAME_SHORTER
                          ]
                        )}
                      >
                        View cover
                      </Link>
                    </div>
                  </div>
                );
              })}
            <h3 className="text-lg md:text-xl font-bold mb-8 mt-12">
              Window Well Liners
            </h3>
            {linerProducts &&
              linerProducts.map((variation, index) => {
                return (
                  <div
                    className="flex flex-wrap md:flex-nowrap mb-6 lg:mb-10 gap-6"
                    key={index}
                  >
                    <div className="w-full md:w-2/5">
                      <Link
                        to={`/window-well-liners/?style=${variation.variationName.replace(
                          " Liner",
                          ""
                        )}`}
                      >
                        <ProductImage
                          className="rounded-lg h-48 w-full object-cover"
                          image={variation.variationFeaturedImage}
                        />
                      </Link>
                    </div>
                    <div className="w-full flex-1">
                      <h4 className="text-lg font-bold mb-1">
                        <Link
                          to={`/window-well-liners/?style=${variation.variationName.replace(
                            " Liner",
                            ""
                          )}`}
                          className={classNames(
                            `hover:underline`,
                            componentStyles.productTitle[
                              process.env.COMPANY_NAME_SHORTER
                            ]
                          )}
                        >
                          {variation.variationName}
                        </Link>
                      </h4>
                      <div className="text-grey-dark font-thin mb-3">
                        <Price value={variation.variationSalePrice} />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: variation.variationShortDescription,
                        }}
                        className="leading-loose mb-2"
                      />
                      <Link
                        to={`/window-well-liners/?style=${variation.variationName.replace(
                          " Liner",
                          ""
                        )}`}
                        className={`text-${process.env.THEME_COLOR_PRIMARY}-dark hover:underline`}
                      >
                        View liner
                      </Link>
                    </div>
                  </div>
                );
              })}
            <h3 className="text-lg md:text-xl font-bold mb-8 mt-12">
              Accessories
            </h3>
            {accessoryProducts &&
              accessoryProducts.map((node, index) => {
                return (
                  <div
                    className="flex flex-wrap md:flex-nowrap mb-6 lg:mb-10 gap-6"
                    key={index}
                  >
                    <div className="w-full md:w-2/5">
                      <Link to={`/${node.slug}/`}>
                        <ProductImage image={node.featuredImage.node} />
                      </Link>
                    </div>
                    <div className="w-full flex-1">
                      <h4 className="text-lg font-bold mb-1">
                        <Link
                          to={`/${node.slug}/`}
                          className={classNames(
                            `hover:underline`,
                            componentStyles.productTitle[
                              process.env.COMPANY_NAME_SHORTER
                            ]
                          )}
                        >
                          {node.title}
                        </Link>
                      </h4>
                      <div className="text-grey-dark font-thin mb-3">
                        <Price
                          value={
                            node.product.salePrice || node.product.basePrice
                          }
                        />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.product.description,
                        }}
                        className="leading-loose mb-2"
                      />
                      <Link
                        to={`/${node.slug}/`}
                        className={`text-${process.env.THEME_COLOR_PRIMARY}-dark hover:underline`}
                      >
                        View accessory
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const ProductImage = ({ image }) => {
  const imageData = getImage(image.localFile);

  return (
    <GatsbyImage
      image={imageData}
      alt={image.altText || "Product"}
      className="rounded-lg h-48 w-full object-cover"
    />
  );
};

export default AllProducts;
